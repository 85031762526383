import React from 'react';
import PropTypes from 'prop-types';
import styles from './Strapline.module.scss';

class Strapline extends React.Component {
    render() {
        const { categoryTag, categoryDescription } = this.props.category;

        return (
            <React.Fragment>
                {categoryTag && (
                    <section className={styles.strapline}>
                        <h2 className={styles.title}>{`${categoryTag}: `}</h2>
                        {categoryDescription && (
                            <h2 className={styles.subTitle}>
                                {categoryDescription.categoryDescription}
                            </h2>
                        )}
                    </section>
                )}
            </React.Fragment>
        );
    }
}

Strapline.propTypes = {
    categoryDescription: PropTypes.shape({
        categoryDescription: PropTypes.string
    })
};

export default Strapline;
