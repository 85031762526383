import React from 'react';
import CategoryPageHeader from './CategoryPageHeader/CategoryPageHeader';
import ArticleList from '../common/ArticleList';
import PressContactBox from '../../../components/Press/PressContactBox';
import CovidInformation from './CovidInformation/CovidInformation';

class CategoryPage extends React.Component {
    render() {
        const { contentfulCategoryPage } = this.props;
        const {
            title,
            category,
            featuredArticles,
            paginationCta,
            recentArticlesTitle,
            pressSection,
            covidInformation
        } = contentfulCategoryPage;

        const articleList = this.props.articleList
            .filter(
                (article) =>
                    !featuredArticles.some(
                        (featuredArticle) => featuredArticle.id === article.id
                    ) && !/.*(SCHEMA).*/gi.test(article.name)
            )
            .sort((articleOne, articleTwo) => {
                return new Date(articleTwo.date) - new Date(articleOne.date);
            });

        const isCovidPage = category.categoryTag === 'Covid-19';

        return (
            <React.Fragment>
                {isCovidPage && <CovidInformation data={covidInformation} />}
                {pressSection && <PressContactBox data={{ ...pressSection }} />}
                <CategoryPageHeader
                    title={title}
                    category={category}
                    featuredArticles={featuredArticles}
                />
                {articleList && articleList.length > 0 && (
                    <ArticleList
                        articleList={articleList}
                        paginationCta={paginationCta}
                        heading={recentArticlesTitle}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default CategoryPage;
