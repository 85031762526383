import React from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './PressContactBox.module.scss';

function PressContactBox(props) {
    const { data } = props;

    return (
        <div className={styles.pressContactBox}>
            <div className={styles.contentContainer}>
                <h2 className={styles.title}>{data.title}</h2>
                {data.subtitle && (
                    <h4 className={styles.subTitle}>{data.subtitle}</h4>
                )}
                <div className={styles.contacts}>
                    {data.contactLink1 && (
                        <ReactMarkdown
                            className={styles.contact}
                            source={data.contactLink1.contactLink1}
                        />
                    )}
                    {data.contactLink2 && (
                        <ReactMarkdown
                            className={styles.contact}
                            source={data.contactLink2.contactLink2}
                        />
                    )}
                    {data.contactLink3 && (
                        <ReactMarkdown
                            className={styles.contact}
                            source={data.contactLink3.contactLink3}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default PressContactBox;
