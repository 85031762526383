import React from 'react';
import { graphql } from 'gatsby';
import CategoryPage from '../../components/ContentHub/CategoryPage/CategoryPage';
import MetaData from '../../components/Layout/MetaData';
import NavBar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';
import ContentHubWrapper from './contentHubWrapper';

class CategoryPageWithoutStore extends React.Component {
    render() {
        const {
            contentfulBlogSubPage,
            contentfulGlobalVariables,
            contentfulFeatureManagementSystem,
            contentfulHreflangManagement
        } = this.props.data;

        const applyForm = contentfulBlogSubPage.modal
            ? contentfulBlogSubPage.modal.find(
                  (modal) => modal.type === 'customer_signup_form'
              )
            : null;

        const {
            titleTag,
            metaDescription,
            allowSearchEngineScraping,
            category,
            slug
        } = contentfulBlogSubPage;

        let canonicalTag = slug;

        if (
            category.categoryTag === 'People of iwoca' ||
            category.categoryTag === 'Product and Design' ||
            category.categoryTag === 'Engineering and Data Science'
        ) {
            canonicalTag = `${category.blog_article[0].categoryTag.categoryLink}/`;
        }

        const articleList = contentfulBlogSubPage.category.blog_article || [];
        return (
            <ContentHubWrapper>
                <MetaData
                    metaData={{
                        titleTag,
                        metaDescription,
                        allowSearchEngineScraping,
                        slug: canonicalTag,
                        contentfulHreflangManagement
                    }}
                />
                <NavBar />
                <CategoryPage
                    contentfulCategoryPage={contentfulBlogSubPage}
                    articleList={articleList}
                />
                <Footer />
            </ContentHubWrapper>
        );
    }
}

export default CategoryPageWithoutStore;

export const query = graphql`
    query SubPageQuery($id: String!) {
        contentfulBlogSubPage(id: { eq: $id }) {
            id
            slug
            name
            titleTag
            metaDescription
            allowSearchEngineScraping
            ...CatergoryPageHeader
            header {
                ...Header
            }
            modal {
                ...Form
            }
            paginationCta
            recentArticlesTitle
            covidInformation {
                information
                pointOne
                pointTwo
            }
            category {
                name
                categoryTag
                blog_article {
                    id
                    slug
                    articleTitle
                    articleHeaderImage {
                        file {
                            url
                        }
                        fluid(maxWidth: 600, quality: 80) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                    subtitle {
                        subtitle
                    }
                    ...ArticleContent
                    categoryTag {
                        categoryTag
                        categoryLink
                        blog_sub_page_ {
                            id
                            slug
                        }
                    }
                }
            }
            pressSection {
                title
                subtitle
                contactLink1 {
                    contactLink1
                }
                contactLink2 {
                    contactLink2
                }
                contactLink3 {
                    contactLink3
                }
            }
        }
        contentfulGlobalVariables {
            ...GlobalVariables
        }
        contentfulFeatureManagementSystem(
            name: { eq: "Feature Management System" }
        ) {
            ...FeatureManagementSystem
        }
        contentfulHreflangManagement(name: { eq: "Hreflang Management" }) {
            ...HreflangManagement
        }
    }
`;
